import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import ls from "local-storage";
import { differenceInMonths } from "date-fns";
import imageCompression from "browser-image-compression";
import { post, defaultChecks, calcCrow, usePrevious } from "./utils";
import "./form.scss";

function Form({ username, userToken, onLogout }) {
  const [authorities, setAuthorities] = useState([]);
  const [selectedAuthority, setSelectedAuthority] = useState(-1);
  const [gardens, setGardens] = useState([]);
  const [selectedGarden, setSelectedGarden] = useState(-1);
  const [checks, setChecks] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState(false);
  const [hiddenGardens, setHiddenGardens] = useState(0);

  const [lastCoords, setLastCoords] = useState(false);
  const prevCoords = usePrevious(lastCoords);
  const [formattedAddress, setFormattedAddress] = useState(false);

  const fileInputRef = useRef();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(async (position) => {
        setLastCoords(position.coords);
      });
    }
  }, []);

  useEffect(() => {
    async function getLocation() {
      const { data } = await axios.post(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lastCoords.latitude},${lastCoords.longitude}&language=iw&key=AIzaSyAqz6-vayKvG7M97UMaAzXLMD0CK5MFLNM`
      );

      if (data.status === "OK") {
        setFormattedAddress(data.results[0].formatted_address);
      }
    }

    if (!prevCoords && lastCoords) {
      getLocation();
      return;
    }

    if (!prevCoords || !lastCoords) {
      return;
    }

    const length = calcCrow(
      prevCoords.latitude,
      prevCoords.longitude,
      lastCoords.latitude,
      lastCoords.longitude
    );

    if (length >= 0.05) {
      getLocation();
    }
  }, [prevCoords, lastCoords]);

  useEffect(() => {
    async function init() {
      if (username && userToken) {
        let data = await post("/authorities/get", {
          user: username,
          token: userToken,
        });

        if (data.result === "error") {
          alert("שגיאה - יש להתחבר מחדש");
          onLogout();
          return;
        }

        const curDate = new Date();
        let hidden = 0;

        for (let i = data.authorities.length - 1; i >= 0; i--) {
          for (let j = data.authorities[i].gardens.length - 1; j >= 0; j--) {
            const val = ls(
              `hide_${data.authorities[i].name}_${data.authorities[i].gardens[j].name}`
            );
            if (val && differenceInMonths(curDate, new Date(val)) < 1) {
              data.authorities[i].gardens.splice(j, 1);
              hidden++;
            }
          }
        }

        setHiddenGardens(hidden);

        setAuthorities([...data.authorities]);
        setLoading(false);
      }
    }

    init();
  }, [username, userToken, onLogout]);

  useEffect(() => {
    if (selectedAuthority > -1) {
      setGardens(authorities[selectedAuthority].gardens);
      setSelectedGarden(-1);
    }
  }, [selectedAuthority, authorities]);

  useEffect(() => {
    if (selectedAuthority > -1 && selectedGarden > -1) {
      const checks =
        authorities[selectedAuthority].gardens[selectedGarden].checks;

      if (checks.length) {
        setChecks(
          checks.map((c) => ({ name: c.name, status: true, description: "" }))
        );
      } else {
        setChecks(
          defaultChecks.map((c) => ({ name: c, status: true, description: "" }))
        );
      }

      setFacilities(
        authorities[selectedAuthority].gardens[selectedGarden].facilities.map(
          (f) => ({ ...f, exists: true })
        )
      );
    }
  }, [selectedGarden, authorities, selectedAuthority]);

  function handleAuthorityChanged(e) {
    setSelectedAuthority(e.target.value);
  }

  function handleGardenChanged(e) {
    setSelectedGarden(e.target.value);
  }

  function handleStatusClicked(idx, status) {
    checks[idx].status = status;
    setChecks([...checks]);
  }

  function handleDescriptionChanged(idx, e) {
    checks[idx].description = e.target.value;
    setChecks([...checks]);
  }

  function handleExistsClicked(facility) {
    facility.exists = true;
    setFacilities([...facilities]);
  }

  function handleNotExistsClicked(facility) {
    facility.exists = false;
    setFacilities([...facilities]);
  }

  async function handleSendFormClicked() {
    ls(
      `hide_${authorities[selectedAuthority].name}_${authorities[selectedAuthority].gardens[selectedGarden].name}`,
      +new Date()
    );

    setLoading(true);

    let data;
    if (username === "גידי") {
      data = { result: "success" };
    } else {
      data = await post("/garden/createReport", {
        user: username,
        token: userToken,
        authority: authorities[selectedAuthority].name,
        garden: authorities[selectedAuthority].gardens[selectedGarden].name,
        checks,
        facilities,
        bedding_type:
          authorities[selectedAuthority].gardens[selectedGarden].bedding_type,
        images,
        latitude: lastCoords.latitude,
        longitude: lastCoords.longitude,
        formattedAddress,
      });
    }

    if (data.result === "error") {
      alert("לא ניתן לשלוח את הדוח לפני שהוגדר סוג מצע");
      setLoading(false);
      return;
    }

    alert("הדוח נשלח בהצלחה!");
    setLoading(false);

    authorities[selectedAuthority].gardens.splice(selectedGarden, 1);
    setAuthorities([...authorities]);

    setSelectedGarden(-1);
    setImages([]);
  }

  function handleShowGardensClicked() {
    if (!window.confirm("להציג את כל הגנים המוסתרים?")) {
      return;
    }

    for (const key in localStorage) {
      if (key.indexOf("hide_") > -1) {
        ls.remove(key);
      }
    }

    window.location.reload();
  }

  function handleTakePictureClicked() {
    fileInputRef.current.click();
  }

  function loadImage(file) {
    return new Promise((resolve) => {
      const fr = new FileReader();

      fr.onload = () => {
        resolve(fr.result);
      };

      fr.readAsDataURL(file);
    });
  }

  async function handleFileInputChanged() {
    setLoadingImages(true);
    const newImages = [...images];

    for (let i = 0; i < fileInputRef.current.files.length; i++) {
      const compressedFile = await imageCompression(
        fileInputRef.current.files[i],
        {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
      );
      const data = await loadImage(compressedFile);
      newImages.push(data);
    }

    setImages(newImages);
    setLoadingImages(false);
  }

  function handleRemoveImageClicked(idx) {
    if (window.confirm("להסיר את התמונה שנלחצה?")) {
      images.splice(idx, 1);
      setImages([...images]);
    }
  }

  if (loading) {
    return <div>טוען...</div>;
  }

  if (!authorities.length) {
    return (
      <div>לא קיימות רשויות - יש לבקש ממנהל המערכת להוסיף רשויות תחילה</div>
    );
  }

  return (
    <div className="form-wrapper">
      {hiddenGardens > 0 && (
        <div>
          <div>
            שימו לב שיש {hiddenGardens} גנים מוסתרים.
            <br />
            <a href="#" onClick={handleShowGardensClicked}>
              לחץ כדי להציג
            </a>
          </div>
          <br />
        </div>
      )}
      <div className="select-wrapper">
        <label className="select-label">
          <select value={selectedAuthority} onChange={handleAuthorityChanged}>
            <option value={-1}>בחר רשות...</option>
            {authorities.map((a, idx) => (
              <option key={idx} value={idx}>
                {a.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <br />
      {selectedAuthority > -1 && (
        <div className="select-wrapper">
          <label className="select-label">
            <select value={selectedGarden} onChange={handleGardenChanged}>
              <option value={-1}>בחר גן...</option>
              {gardens.map((g, idx) => (
                <option key={idx} value={idx}>
                  {g.name}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}
      {selectedGarden > -1 && (
        <Fragment>
          <div>
            <span className="bold">סוג מצע: </span>
            {
              authorities[selectedAuthority].gardens[selectedGarden]
                .bedding_type
            }
          </div>
          <br />
          <div className="checks">
            {checks.map((c, idx) => (
              <div key={idx}>
                <div className="bold">{c.name}</div>
                <div className="status">
                  <img
                    onClick={() => handleStatusClicked(idx, true)}
                    src="/check.jpg"
                    alt="V"
                    className={`${c.status === true ? "checked" : ""}`}
                  />
                  <img
                    onClick={() => handleStatusClicked(idx, false)}
                    src="/x.png"
                    alt="X"
                    className={`${c.status === false ? "checked" : ""}`}
                  />
                </div>
                <div className="description">
                  <textarea
                    placeholder="הערות..."
                    value={c.description}
                    onChange={(e) => handleDescriptionChanged(idx, e)}
                  ></textarea>
                </div>
                <hr />
              </div>
            ))}
          </div>
          <br />
          <h2>רשימת מתקנים:</h2>
          <div className="facilities">
            {facilities.map((f, idx) => (
              <Fragment key={idx}>
                <div className="facility">
                  <div className="bold">{f.name}</div>
                  <label>
                    <input
                      name={`${f.name}_${idx}`}
                      type="radio"
                      checked={!!f.exists}
                      onChange={() => handleExistsClicked(f)}
                    />
                    קיים
                  </label>
                  <label>
                    <input
                      name={`${f.name}_${idx}`}
                      type="radio"
                      checked={!f.exists}
                      onChange={() => handleNotExistsClicked(f)}
                    />
                    לא קיים
                  </label>
                </div>
                <hr />
              </Fragment>
            ))}
          </div>
          <br />
          <h2>תמונות:</h2>
          <div className="images-wrapper">
            {images.map((imageData, idx) => (
              <img
                key={idx}
                src={imageData}
                alt="img"
                onClick={() => handleRemoveImageClicked(idx)}
              />
            ))}
          </div>
          <div>
            {images.length > 0 && <div>כדי להסיר תמונה יש ללחוץ עליה</div>}
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              multiple
              onChange={handleFileInputChanged}
            />
            <button onClick={handleTakePictureClicked} disabled={loadingImages}>
              {loadingImages ? "מוסיף..." : "הוסף תמונה"}
            </button>
          </div>
          <br />
          {formattedAddress && (
            <div>
              <b>מיקום:</b> {formattedAddress}
            </div>
          )}
          <br />
          <button
            disabled={
              loading ||
              selectedAuthority === -1 ||
              selectedGarden === -1 ||
              checks.find((c) => c.status === null)
            }
            onClick={handleSendFormClicked}
          >
            שליחת טופס
          </button>
        </Fragment>
      )}
    </div>
  );
}

Form.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
  onLogout: PropTypes.func,
};

export default Form;
