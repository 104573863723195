import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { post } from "./utils";

function AdminAuthorities({ username, userToken }) {
  const [authorities, setAuthorities] = useState([]);
  const [duplicates, setDuplicates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (!userToken || !username) {
        return;
      }

      let data = await post("/authorities/get", {
        user: username,
        token: userToken,
      });

      setAuthorities(data.authorities);

      const duplicates = {};
      data.authorities.forEach((authority) => {
        const aname = authority.name.trim();
        duplicates[aname] = duplicates[aname] ? duplicates[aname] + 1 : 1;
      });
      setDuplicates(duplicates);

      setLoading(false);
    }

    init();
  }, [userToken, username]);

  function handleAuthorityChanged(e, authority) {
    authority.name = e.target.value;
    setAuthorities([...authorities]);
  }

  function handleAuthorityDeleted(authority) {
    setAuthorities([...authorities.filter((a) => a !== authority)]);
  }

  function handleAddAuthorityClicked() {
    authorities.push({ name: "", gardens: [] });
    setAuthorities([...authorities]);
  }

  function handleSortClicked() {
    authorities.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    });
    setAuthorities([...authorities]);
  }

  async function handleAuthoritiesSaveClicked() {
    setLoading(true);
    await post("/authorities/save", {
      user: username,
      token: userToken,
      authorities,
    });
    setLoading(false);
    alert("נשמר בהצלחה");
  }

  function renderDuplicates() {
    const renderArray = Object.keys(duplicates)
      .filter((k) => duplicates[k] > 1)
      .map((key, idx) => (
        <div key={idx}>
          {key} - מופיעה {duplicates[key]} פעמים
        </div>
      ));

    if (!renderArray.length) {
      return null;
    }

    return (
      <div>
        <div>
          <b>שימו לב! יש כפל רשויות שמומלץ לתקן:</b>
        </div>
        {renderArray}
      </div>
    );
  }

  if (loading) {
    return <div>טוען...</div>;
  }

  return (
    <div>
      {renderDuplicates()}
      <table>
        <thead>
          <tr>
            <th>שם רשות</th>
            <th>גנים</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {authorities.length === 0 && (
            <tr>
              <td colSpan={3}>לא קיימות רשויות</td>
            </tr>
          )}
          {authorities.map((authority, idx) => (
            <Fragment key={idx}>
              <tr>
                <td>
                  <input
                    type="text"
                    value={authority.name}
                    onChange={(e) => handleAuthorityChanged(e, authority)}
                  />
                </td>
                <td>מכילה {authority.gardens.length} גנים</td>
                <td>
                  <button onClick={() => handleAuthorityDeleted(authority)}>
                    מחיקת רשות
                  </button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>

      <br />

      <button onClick={handleAddAuthorityClicked}>הוספת רשות</button>
      <div className="gutter"></div>
      <button onClick={handleSortClicked}>מיון</button>
      <div className="gutter"></div>
      <button disabled={loading} onClick={handleAuthoritiesSaveClicked}>
        שמירה
      </button>
    </div>
  );
}

AdminAuthorities.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default AdminAuthorities;
