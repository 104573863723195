import React, { useEffect, useState, useCallback, Fragment } from "react";
import "./App.scss";
import Form from "./form";
import Login from "./login";
import Admin from "./admin";

export default function App() {
  const [username, setUsername] = useState(false);
  const [userToken, setUserToken] = useState(false);
  const [userRole, setUserRole] = useState(false);

  const [loginState, setLoginState] = useState(0);

  const getDataFromLocalStorage = useCallback(() => {
    const userName = localStorage.getItem("user-name");
    const userToken = localStorage.getItem("user-token");
    const userRole = localStorage.getItem("user-role");

    setUsername(userName);
    setUserToken(userToken);
    setUserRole(userRole);
  }, []);

  function handleConnected() {
    getDataFromLocalStorage();
  }

  function handleLogoutClicked() {
    localStorage.removeItem("user-name");
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-role");

    getDataFromLocalStorage();
  }

  useEffect(() => {
    getDataFromLocalStorage();
  }, [getDataFromLocalStorage]);

  useEffect(() => {
    setLoginState(userToken ? 2 : 1);
  }, [username, userToken, userRole]);

  return (
    <div className="app-wrapper">
      {loginState === 1 && <Login onConnected={handleConnected} />}
      {loginState === 2 && (
        <Fragment>
          <div>
            שלום {username}{" "}
            <a href="#" onClick={handleLogoutClicked}>
              התנתק
            </a>
          </div>
          <br />
        </Fragment>
      )}
      {loginState === 2 && userRole === "user" && (
        <Form
          username={username}
          userToken={userToken}
          onLogout={handleLogoutClicked}
        />
      )}
      {loginState === 2 && userRole === "admin" && (
        <Admin username={username} userToken={userToken} />
      )}
      <hr />
    </div>
  );
}
