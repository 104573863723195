import { useRef, useEffect } from "react";
import axios from "axios";

export function calcCrow(lat1, lon1, lat2, lon2) {
  var R = 6371;
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export const SERVER_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://garden.dafner.net/api";

export const post = async (url, params) => {
  const { data } = await axios.post(
    `${
      process.env.NODE_ENV === "development"
        ? "http://localhost:3001"
        : "https://garden.dafner.net/api"
    }${url}`,
    params
  );

  return data;
};

export const defaultChecks = [
  "במידה וקיים כבל חשמל לא מאושר סמן X",
  "המשטח כולם תקין ונקי?",
  "יש מדבקות גיל משתמש?",
  "הגישה למתקנים פנויה?",
  "לא קיים חופש בין היסודות לקרקע?",
  "המתקנים לא נוטים על צידם?",
  "אין יסודות בולטים?",
  "אין שחיקת צבע או חלודה?",
  "אין חדויות במתקנים?",
  "המתקנים לא רופפים?",
  "לא חסרים חלקים?",
  "לא קיימים סדקים או שברים?",
  "כלל אמצעי החיבור קיימים ומחוזקים?",
  "השרשראות, כלים או צמיגים תקינים?",
  "הקפיצים תקינים?",
  "האם קיים מחסום?",
  "מתאים לדרישות התקן?",
];

export const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
