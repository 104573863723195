import "./admin.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import AdminUsers from "./admin-users";
import AdminAuthorities from "./admin-authorities";
import AdminReports from "./admin-reports";
import AdminChecks from "./admin-checks";
import AdminGardens from "./admin-gardens";

function Admin({ username, userToken }) {
  const [page, setPage] = useState("users");

  function handleNavClicked(page) {
    setPage(page);
  }

  return (
    <div className="admin-wrapper">
      <br />
      <div className="nav">
        <a href="#" onClick={() => handleNavClicked("users")}>
          משתמשים
        </a>
        <a href="#" onClick={() => handleNavClicked("authorities")}>
          רשויות
        </a>
        <a href="#" onClick={() => handleNavClicked("gardens")}>
          גנים
        </a>
        <a href="#" onClick={() => handleNavClicked("checks")}>
          בדיקות ומתקנים
        </a>
        <a href="#" onClick={() => handleNavClicked("reports")}>
          דוחות
        </a>
      </div>
      <br />
      {page === "users" && (
        <AdminUsers username={username} userToken={userToken} />
      )}
      {page === "authorities" && (
        <AdminAuthorities username={username} userToken={userToken} />
      )}
      {page === "gardens" && (
        <AdminGardens username={username} userToken={userToken} />
      )}
      {page === "checks" && (
        <AdminChecks username={username} userToken={userToken} />
      )}
      {page === "reports" && (
        <AdminReports username={username} userToken={userToken} />
      )}
    </div>
  );
}

Admin.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default Admin;
