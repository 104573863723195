import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { post } from "./utils";

function AdminGardens({ username, userToken }) {
  const [authorities, setAuthorities] = useState([]);
  const [selectedAuthority, setSelectedAuthority] = useState(-1);
  const [duplicates, setDuplicates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (!userToken || !username) {
        return;
      }

      let data = await post("/authorities/get", {
        user: username,
        token: userToken,
      });

      setAuthorities(data.authorities);
      setLoading(false);
    }

    init();
  }, [userToken, username]);

  useEffect(() => {
    if (selectedAuthority !== -1 && authorities[selectedAuthority]) {
      const duplicates = {};
      authorities[selectedAuthority].gardens.forEach((garden) => {
        const gname = garden.name.trim();
        duplicates[gname] = duplicates[gname] ? duplicates[gname] + 1 : 1;
      });
      setDuplicates(duplicates);
    }
  }, [selectedAuthority, authorities]);

  function handleGardenChanged(e, garden) {
    garden.name = e.target.value;
    setAuthorities([...authorities]);
  }

  function handleGardenDeleteClicked(garden) {
    authorities[selectedAuthority].gardens = authorities[
      selectedAuthority
    ].gardens.filter((g) => g !== garden);
    setAuthorities([...authorities]);
  }

  async function handleAuthoritiesSaveClicked() {
    setLoading(true);
    await post("/authorities/save", {
      user: username,
      token: userToken,
      authorities,
    });
    setLoading(false);
    alert("נשמר בהצלחה");
  }

  function handleGardenAddClicked() {
    authorities[selectedAuthority].gardens.push({ name: "" });
    setAuthorities([...authorities]);
  }

  function handleSortClicked() {
    authorities[selectedAuthority].gardens.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    });
    setAuthorities([...authorities]);
  }

  function renderDuplicates() {
    const renderArray = Object.keys(duplicates)
      .filter((k) => duplicates[k] > 1)
      .map((key, idx) => (
        <div key={idx}>
          {key} - מופיע {duplicates[key]} פעמים
        </div>
      ));

    if (!renderArray.length) {
      return null;
    }

    return (
      <div>
        <div>
          <b>שימו לב! יש כפל גנים שמומלץ לתקן:</b>
        </div>
        {renderArray}
      </div>
    );
  }

  if (loading) {
    return <div>טוען...</div>;
  }

  return (
    <div>
      <select
        value={selectedAuthority}
        onChange={(e) => setSelectedAuthority(e.target.value)}
      >
        <option value={-1}>בחר רשות...</option>
        {authorities.map((a, idx) => (
          <option key={idx} value={idx}>
            {a.name}
          </option>
        ))}
      </select>

      <br />
      {renderDuplicates()}
      <br />

      {selectedAuthority > -1 && (
        <Fragment>
          <table>
            <thead>
              <tr>
                <th>שם גן</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {authorities[selectedAuthority].gardens.length === 0 && (
                <tr>
                  <td colSpan={2}>לא קיימים גנים</td>
                </tr>
              )}
              {authorities[selectedAuthority].gardens.map((g, idx) => (
                <tr key={idx}>
                  <td>
                    <input
                      type="text"
                      value={g.name}
                      onChange={(e) => handleGardenChanged(e, g)}
                    />
                  </td>
                  <td>
                    <button onClick={() => handleGardenDeleteClicked(g)}>
                      מחיקה
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <br />

          <button onClick={() => handleGardenAddClicked()}>הוספה</button>
          <div className="gutter"></div>
          <button onClick={handleSortClicked}>מיון</button>
          <div className="gutter"></div>
          <button
            disabled={loading}
            onClick={() => handleAuthoritiesSaveClicked()}
          >
            שמירה
          </button>
        </Fragment>
      )}
    </div>
  );
}

AdminGardens.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default AdminGardens;
