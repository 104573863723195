import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { post } from "./utils";

function AdminUsers({ username, userToken }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (!userToken || !username) {
        return;
      }

      const data = await post("/users/get", {
        user: username,
        token: userToken,
      });

      if (data.result === "error") {
        localStorage.clear();
        window.location.reload();
        return;
      }

      setUsers(data.users);
      setLoading(false);
    }

    init();
  }, [userToken, username]);

  function handleUserChanged(e, idx) {
    users[idx].name = e.target.value;
    setUsers([...users]);
  }

  function handlePasswordChanged(e, idx) {
    users[idx].password = e.target.value;
    setUsers([...users]);
  }

  function handleRoleChanged(e, idx) {
    users[idx].role = e.target.value;
    setUsers([...users]);
  }

  function handleDeleteUserClicked(idx) {
    users.splice(idx, 1);
    setUsers([...users]);
  }

  function handleAddUserClicked() {
    users.push({ name: "", password: "", role: "user" });
    setUsers([...users]);
  }

  function handleSortClicked() {
    users.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    });
    setUsers([...users]);
  }

  async function handleUsersSaveClicked() {
    setLoading(true);
    await post("/users/save", {
      user: username,
      token: userToken,
      users: users.map((u) => ({ ...u, name: u.name.toLowerCase() })),
    });
    setLoading(false);
    alert("נשמר בהצלחה");
  }

  if (loading) {
    return <div>טוען...</div>;
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>שם</th>
            <th>סיסמא</th>
            <th>תפקיד</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, idx) => (
            <tr key={idx}>
              <td>
                <input
                  value={user.name}
                  onChange={(e) => handleUserChanged(e, idx)}
                  disabled={user.name === "yoram"}
                />
              </td>
              <td>
                <input
                  value={user.password}
                  onChange={(e) => handlePasswordChanged(e, idx)}
                />
              </td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => handleRoleChanged(e, idx)}
                  disabled={user.name === "yoram"}
                >
                  <option value="user">משתמש</option>
                  <option value="admin">מנהל</option>
                </select>
              </td>
              <td>
                {user.name !== "yoram" && (
                  <button onClick={() => handleDeleteUserClicked(idx)}>
                    מחיקה
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />

      <button onClick={handleAddUserClicked}>הוספה</button>
      <div className="gutter"></div>
      <button onClick={handleSortClicked}>מיון</button>
      <div className="gutter"></div>
      <button disabled={loading} onClick={handleUsersSaveClicked}>
        שמירה
      </button>
    </div>
  );
}

AdminUsers.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default AdminUsers;
