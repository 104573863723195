import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { post, defaultChecks } from "./utils";
import "./admin-checks.scss";

function AdminChecks({ username, userToken }) {
  const [authorities, setAuthorities] = useState([]);
  const [selectedAuthority, setSelectedAuthority] = useState(-1);
  const [selectedGarden, setSelectedGarden] = useState(-1);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      if (!userToken || !username) {
        return;
      }

      let data = await post("/authorities/get", {
        user: username,
        token: userToken,
      });

      data.authorities.forEach((authority) => {
        authority.gardens.forEach((garden) => {
          if (!garden.checks.length) {
            garden.checks = defaultChecks.map((c) => ({ name: c }));
          }
          garden.facilities = garden.facilities || [];
          garden.bedding_type = garden.bedding_type || "";
        });
      });

      setAuthorities(data.authorities);
      setLoading(false);
    }

    init();
  }, [userToken, username]);

  function handleAddCheckClicked() {
    authorities[selectedAuthority].gardens[selectedGarden].checks.push({
      name: "",
    });
    setAuthorities([...authorities]);
  }

  function handleCheckChanged(e, check) {
    check.name = e.target.value;
    setAuthorities([...authorities]);
  }

  function handleCheckDeleteClicked(check) {
    authorities[selectedAuthority].gardens[selectedGarden].checks = authorities[
      selectedAuthority
    ].gardens[selectedGarden].checks.filter((c) => c !== check);
    setAuthorities([...authorities]);
  }

  function handleUserDefaultChecksClicked() {
    authorities[selectedAuthority].gardens[selectedGarden].checks =
      defaultChecks.map((c) => ({ name: c }));
    setAuthorities([...authorities]);
  }

  function handleFacilityChanged(e, facility) {
    facility.name = e.target.value;
    setAuthorities([...authorities]);
  }

  function handleFacilityDeleteClicked(facility) {
    authorities[selectedAuthority].gardens[selectedGarden].facilities =
      authorities[selectedAuthority].gardens[selectedGarden].facilities.filter(
        (c) => c !== facility
      );
    setAuthorities([...authorities]);
  }

  function handleAddFacilityClicked() {
    authorities[selectedAuthority].gardens[selectedGarden].facilities.push({
      name: "",
    });
    setAuthorities([...authorities]);
  }

  function handleBeddingTypeChanged(e) {
    authorities[selectedAuthority].gardens[selectedGarden].bedding_type =
      e.target.value;
    setAuthorities([...authorities]);
  }

  function handleSortChecksClicked() {
    authorities[selectedAuthority].gardens[selectedGarden].checks.sort(
      (a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      }
    );
    setAuthorities([...authorities]);
  }

  function handleSortFacilitiesClicked() {
    authorities[selectedAuthority].gardens[selectedGarden].facilities.sort(
      (a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      }
    );
    setAuthorities([...authorities]);
  }

  async function handleSaveClicked() {
    setLoading(true);
    await post("/authorities/save", {
      user: username,
      token: userToken,
      authorities,
    });
    setLoading(false);
    alert("נשמר בהצלחה");
  }

  if (loading) {
    return <div>טוען...</div>;
  }

  return (
    <div className="admin-checks-wrapper">
      <div>
        <select
          value={selectedAuthority}
          onChange={(e) => setSelectedAuthority(e.target.value)}
        >
          <option value={-1}>בחר רשות...</option>
          {authorities.map((a, idx) => (
            <option key={idx} value={idx}>
              {a.name}
            </option>
          ))}
        </select>
      </div>

      <br />

      {selectedAuthority !== -1 && authorities[selectedAuthority] && (
        <div>
          <select
            value={selectedGarden}
            onChange={(e) => setSelectedGarden(e.target.value)}
          >
            <option value={-1}>בחר גן...</option>
            {authorities[selectedAuthority].gardens.map((g, idx) => (
              <option key={idx} value={idx}>
                {g.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <br />

      {selectedGarden !== -1 &&
        authorities[selectedAuthority] &&
        authorities[selectedAuthority].gardens[selectedGarden] && (
          <div>
            <h2>בדיקות:</h2>
            <table>
              <thead>
                <tr>
                  <th>שם בדיקה</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {authorities[selectedAuthority].gardens[selectedGarden].checks
                  .length === 0 && (
                  <tr>
                    <td colSpan={2}>לא קיימות בדיקות</td>
                  </tr>
                )}
                {authorities[selectedAuthority].gardens[
                  selectedGarden
                ].checks.map((c, idx) => (
                  <tr key={idx}>
                    <td>
                      <input
                        type="text"
                        value={c.name}
                        onChange={(e) => handleCheckChanged(e, c)}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleCheckDeleteClicked(c)}>
                        מחיקה
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <br />

            <button onClick={() => handleAddCheckClicked()}>הוספה</button>
            <div className="gutter"></div>
            <button onClick={handleSortChecksClicked}>מיון</button>
            <div className="gutter"></div>
            <button onClick={() => handleUserDefaultChecksClicked()}>
              שימוש בשדות ברירת המחדל
            </button>

            <div>
              <br />
            </div>

            <h2>מתקנים:</h2>
            <table>
              <thead>
                <tr>
                  <th>שם מתקן</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {authorities[selectedAuthority].gardens[selectedGarden]
                  .facilities.length === 0 && (
                  <tr>
                    <td colSpan={2}>לא קיימים מתקנים</td>
                  </tr>
                )}
                {authorities[selectedAuthority].gardens[
                  selectedGarden
                ].facilities.map((f, idx) => (
                  <tr key={idx}>
                    <td>
                      <input
                        type="text"
                        value={f.name}
                        onChange={(e) => handleFacilityChanged(e, f)}
                      />
                    </td>
                    <td>
                      <button onClick={() => handleFacilityDeleteClicked(f)}>
                        מחיקה
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <br />

            <button onClick={() => handleAddFacilityClicked()}>הוספה</button>
            <div className="gutter"></div>
            <button onClick={handleSortFacilitiesClicked}>מיון</button>

            <div>
              <br />
            </div>

            <h2>שדות נוספים:</h2>
            <table>
              <tbody>
                <tr>
                  <td>סוג מצע</td>
                  <td>
                    <input
                      type="text"
                      value={
                        authorities[selectedAuthority].gardens[selectedGarden]
                          .bedding_type
                      }
                      onChange={(e) => handleBeddingTypeChanged(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <br />

            <button disabled={loading} onClick={() => handleSaveClicked()}>
              שמירה
            </button>
          </div>
        )}
    </div>
  );
}

AdminChecks.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default AdminChecks;
