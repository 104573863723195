import "./login.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { post } from "./utils";

function Login({ onConnected = () => {} }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function handleConnectClicked() {
    if (!username || !password) {
      return;
    }

    const data = await post("/user/login", {
      user: username.toLowerCase(),
      password,
    });

    if (data.result === "success") {
      localStorage.setItem("user-name", username.toLowerCase());
      localStorage.setItem("user-role", data.user.role);
      localStorage.setItem("user-token", data.user.token);
      onConnected();
    } else {
      alert("סיסמא שגוייה");
    }
  }

  return (
    <div className="login-wrapper">
      <span>שם משתמש:</span>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyUp={(e) => e.keyCode === 13 && handleConnectClicked()}
      />
      <br />
      <span>סיסמא:</span>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyUp={(e) => e.keyCode === 13 && handleConnectClicked()}
      />
      <br />
      <button onClick={handleConnectClicked}>התחברות</button>
    </div>
  );
}

Login.propTypes = {
  onConnected: PropTypes.func,
};

export default Login;
