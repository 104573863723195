import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { format, addMonths } from "date-fns";
import { post, SERVER_URL } from "./utils";
import "./admin-reports.scss";

function AdminReports({ username, userToken }) {
  const [reports, setReports] = useState(null);
  const [authoritiesNames, setAuthoritiesNames] = useState(null);

  const [fromDate, setFromDate] = useState(
    format(addMonths(new Date(), -1), "yyyy-MM-dd")
  );
  const [toDate, setToDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [authorityToShow, setAuthorityToShow] = useState("-1");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function init() {
      let data = await post("/authorities/getAllNames", {
        user: username,
        token: userToken,
        removeGardens: true,
      });

      setAuthoritiesNames(data.names);
      setLoading(false);
    }

    init();
  }, [username, userToken]);

  async function handleShowReportClicked(report) {
    report.show = !report.show;
    setReports([...reports]);
  }

  async function handleDeleteReportClicked(report) {
    if (window.confirm("האם אתם בטוחים?")) {
      setLoading(true);
      await post("/reports/delete", {
        user: username,
        token: userToken,
        reportID: report._id,
      });
      setReports([...reports.filter((r) => r._id !== report._id)]);
      setLoading(false);
    }
  }

  async function handleFilterReportsClicked() {
    setLoading(true);
    const data = await post("/reports/get", {
      user: username,
      token: userToken,
      fromDate,
      toDate,
      authorityToShow,
    });
    setReports(data.reports);
    setLoading(false);
  }

  function getReportsIds() {
    let ids = "";

    reports.forEach((report) => {
      ids += `${report._id},`;
    });

    return ids.substring(0, ids.length - 1);
  }

  function renderImages(report) {
    const imagesElements = [];

    for (let i = 0; i < report.images; i++) {
      imagesElements.push(
        <a
          key={i}
          target="_blank"
          rel="noopener noreferrer"
          href={`${SERVER_URL}/images/${report._id}_${i}.png`}
        >
          <img
            src={`${SERVER_URL}/images/${report._id}_${i}.png`}
            alt="garden"
          />
        </a>
      );
    }

    return imagesElements;
  }

  function renderReports() {
    if (loading) {
      return <div>טוען...</div>;
    }

    if (!reports) {
      return null;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>תאריך</th>
            <th>שם משתמש</th>
            <th>רשות</th>
            <th>גן</th>
            <th>הערות</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {reports.length === 0 && (
            <tr>
              <td colSpan={5}>לא קיימים דוחות</td>
            </tr>
          )}
          {reports.map((r, idx) => {
            const failedChecks = r.checks.filter((c) => !c.status).length;
            const failedFacilities = r.facilities.filter(
              (f) => !f.exists
            ).length;

            return (
              <Fragment key={idx}>
                <tr>
                  <td style={{ direction: "ltr" }}>
                    {format(new Date(r.created_at), "d/M/yyyy HH:mm:ss")}
                  </td>
                  <td>{r.user_name}</td>
                  <td>{r.authority_name}</td>
                  <td>{r.garden_name}</td>
                  <td style={{ textAlign: "right" }}>
                    <p>
                      {failedChecks > 0 && `* ${failedChecks} בדיקות לא תקינות`}
                    </p>
                    <p>
                      {failedFacilities > 0 &&
                        `* ${failedFacilities} מתקנים לא קיימים`}
                    </p>
                    {r.images > 0 && <p>* מכיל {r.images} תמונות</p>}
                  </td>
                  <td>
                    <button onClick={() => handleShowReportClicked(r)}>
                      {r.show ? "סגירה" : "צפייה"}
                    </button>
                    <div className="gutter"></div>
                    <a
                      href={`${SERVER_URL}/report/${encodeURIComponent(
                        r._id
                      )}/${encodeURIComponent(username)}/report.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button"
                    >
                      <button>יצוא לPDF</button>
                    </a>
                    <br />
                    <br />
                    <button onClick={() => handleDeleteReportClicked(r)}>
                      {`מחיקת דו"ח`}
                    </button>
                  </td>
                </tr>
                {r.show && (
                  <Fragment>
                    <tr>
                      <td colSpan={2}>
                        <table>
                          <thead>
                            <tr>
                              <th>בדיקה</th>
                              <th>סטטוס</th>
                              <th>הערות</th>
                            </tr>
                          </thead>
                          <tbody>
                            {r.checks.map((c, idx) => (
                              <tr key={idx}>
                                <td>{c.name}</td>
                                <td>
                                  <img
                                    src={`/${c.status ? "check.jpg" : "x.png"}`}
                                    alt="status"
                                  />
                                </td>
                                <td>{c.description}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <div>
                          <b>סוג מצע:</b> {r.bedding_type}
                        </div>
                        <br />
                        {r.formattedAddress && (
                          <div>
                            <b>מיקום:</b> {r.formattedAddress}
                          </div>
                        )}
                        <br />
                        {r.latitude && (
                          <div>
                            <b>latitude:</b> {r.latitude}
                          </div>
                        )}
                        <br />
                        {r.longitude && (
                          <div>
                            <b>longitude:</b> {r.longitude}
                          </div>
                        )}
                      </td>
                      <td colSpan={2}>
                        <table>
                          <thead>
                            <tr>
                              <th>מתקן</th>
                              <th>קיים?</th>
                            </tr>
                          </thead>
                          <tbody>
                            {r.facilities.map((f, idx) => (
                              <tr key={idx}>
                                <td>{f.name}</td>
                                <td>
                                  <img
                                    src={`/${f.exists ? "check.jpg" : "x.png"}`}
                                    alt="status"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    {r.images && r.images > 0 && (
                      <tr>
                        <td colSpan={5}>
                          <div>תמונות</div>
                          <div className="garden-images-wrapper">
                            {renderImages(r)}
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }

  if (!authoritiesNames) {
    return <div>טוען...</div>;
  }

  return (
    <div className="admin-reports-wrapper">
      <div className="filter-section">
        <div>
          <b>סינון:</b>
        </div>
        <div>
          <span>דוח מתאריך</span>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          <span className="second">עד תאריך</span>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <br />
        <div>
          <span>רשויות:</span>
          <select
            value={authorityToShow}
            onChange={(e) => setAuthorityToShow(e.target.value)}
          >
            <option value={-1}>כל הרשויות</option>
            {authoritiesNames.map((authorityName, idx) => (
              <option key={idx} value={authorityName}>
                {authorityName}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div>
          <button onClick={handleFilterReportsClicked}>סנן</button>
          <div className="gutter"></div>
          {reports && reports.length > 1 && (
            <a
              href={`${SERVER_URL}/report/${getReportsIds()}/${username}/report.pdf`}
              target="_blank"
              rel="noopener noreferrer"
              className="button"
            >
              <button>יצוא כל הדוחות לPDF</button>
            </a>
          )}
        </div>
        <br />
      </div>
      {renderReports()}
    </div>
  );
}

AdminReports.propTypes = {
  username: PropTypes.string,
  userToken: PropTypes.string,
};

export default AdminReports;
